.toolbar-item {
  margin-left: 25px !important;
  font-size: 16px !important;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.toolbar-item a {
  text-decoration: none;
  color: white;
}

.dashboard-title {
  margin-right: 30px !important;
}
