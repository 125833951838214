.users-action-cell {
  width: 100px;
}

.user-search-with-button {
  width: calc(100% - 180px) !important;
  display: inline-block;
}

.users-add-button {
  float: right;
  margin-top: 28px !important;
}

.sort-arrow {
  padding-left: 10px;
}

.users-root {
  max-width: 800px;
  margin: 65px auto 0px auto;
}
