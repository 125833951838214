.dashboard-root {
  margin-top: 65px;
}

.dashboard-tree {
  width: 400px;
  display: inline-block;
  border-right: 1px solid #dedede;
  float: left;
}

.dashboard-workspace {
  width: calc(100% - 400px);
  display: inline-block;
  padding: 0px 16px 16px 16px;
  float: right;
  overflow: scroll;
}

.entity-search {
  width: calc(100% - 90px) !important;
  margin-top: 5px !important;
}

.entity-search-full-width {
  width: calc(100% - 45px) !important;
  margin-top: 5px !important;
}

.entity-search-button {
  margin-top: 14px !important;
  margin-left: 10px !important;
}

.tree-toolbar {
  border-bottom: 1px solid #dedede;
  padding-left: 16px;
  background-color: #eaeaea;
}

.dashboard-info-message {
  font-size: 14px;
  padding-top: 23px;
  display: block;
  text-align: left;
}

.dashboard-parent-message {
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
  display: inline-block;
  text-align: left;
}

.entity-details-wrapper {
}

.buttons-wrapper {
  text-align: right;
}

.entity-details-separator {
  height: 10px;
  border-bottom: 1px solid #dedede;
}

.entity-save-button {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.entity-delete-button {
  margin-right: 10px !important;
}

.input-3-line {
  width: 32%;
  margin-right: 10px !important;
}

.input-3-line-last {
  width: calc(36% - 20px) !important;
  margin-right: 0px !important;
}

.tree-item {
  padding: 8px 16px;
  display: inline-block;
  width: calc(100% - 60px);
  cursor: pointer;
}

.item-add-button, .item-add-button-shifted {
  float: right;
  margin-top: 8px;
  margin-right: 5px;
  color: #3f50b5;
  cursor: pointer;
}

.item-add-button-shifted {
  margin-right: 5px;
}

.item-edit-button {
  float: right;
  margin-top: 24px;
  margin-right: 8px;
  color: #3f50b5;
  cursor: pointer;
}

.tree-item svg {
  vertical-align: middle;
  position: relative;
  top: -2px;
  padding-left: 5px;
  margin-right: 5px;
  color: gray;
}

.tree-items-wrapper {
  overflow-y: scroll;
}

.tree-item-selected {
  background-color: #0080ff26;
}

.category-icons-wrapper, .track-icons-wrapper {
  padding-top: 20px;
  text-align: left;
  cursor: pointer;
}

.category-icons-wrapper div, .track-icons-wrapper div {
  margin-right: 10px;
  border: 2px solid #00000000;
  border-radius: 2px;
  display: inline-block;
}

.track-icons-wrapper div {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  vertical-align: top;
}

.category-icons-wrapper div {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  vertical-align: top;
}

.icon-add-button {
  font-size: 50px !important;
  color: #3f50b5;
  margin: 25px;
}

.add-icon-wrapper {
  vertical-align: middle;
}

.track-icons-wrapper img {
  width: 96px;
  max-height: 96px;
  float: left;
  position: relative;
  top: -25px;
}

.category-icons-wrapper img {
  width: 96px;
  max-height: 96px;
  float: left;
  position: relative;
  top: -25px;
}

.track-icon-edit-button {
  float: right;
  color: #3f50b5;
  z-index: 1000;
  cursor: pointer;
  position: relative;
  border: 3px #3f50b5 solid;
  border-radius: 12px;
  padding: 1px;
  top: 3px;
  right: 4px;
}


.category-icon-edit-button {
  float: right;
  color: #3f50b5;
  z-index: 1000;
  cursor: pointer;
  position: relative;
  border: 3px #3f50b5 solid;
  border-radius: 12px;
  padding: 1px;
  top: 3px;
  right: 4px;
}


.entity-type-select {
  position: relative;
  top: 5px;
}

.category-icon-selected {
  border: 2px solid #3f50b57a !important;
  transform: scale(1.1);
}

/*.category-icons-wrapper img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  vertical-align: middle;
}*/

.track-icon-selected {
  border: 2px solid #3f50b57a !important;
  transform: scale(1.1);
  padding-top: 1px;
}

.select-icon {
  width: 100%;
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
}

.statistics-info {
  width: 100%;
  display: block;
  font-size: 16px;
  padding-top: 20px;
  text-align: left;
}

.track-type-select {
  position: relative;
  top: 5px;
}

.track-file-input {
  padding-top: 24px !important;
}

.track-icon-input {
  padding-top: 8px !important;
}
