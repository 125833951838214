.login-paper {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  width: '100%';
  margin-top: 8px;
}

.login-submit {
  margin-top: 8px !important;
}
